import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { DeviceUUID } from 'device-uuid';
import { getUserAgent } from 'universal-user-agent';
import _ from 'lodash';
import ProgressBar from '../sales-order/components/ProgressBar.jsx';
import { receiveApi } from 'Helpers/helpers';
import { setDefaultShopOrderData } from 'Redux/actions_ui';
import {
	updateShopOrder,
	postApproval,
	confirmShopOrder,
	holdShopOrder,
	viewOrder,
	printOrder,
} from 'Redux/actions';
import * as cons from 'Redux/constants';
import loc from 'Components/languages';
import PageShopOrderSalesman from '../sales-order/salesman';
import PageShopOrderCart from '../sales-order/cart';
import ModalPromotions from '../sales-order/components/ModalPromotions.jsx';
import ModalCartException from '../sales-order/components/ModalCartException.jsx';
import ModalApproval from '../order-search/components/ModalApproval.jsx';
import ModalSpecialOptions from '../sales-order/components/ModalSpecialOptions.jsx';

class PageShopOrder extends PureComponent {
	constructor() {
		super();
		this.defaultShopOrder = {
			salesman_code1: null,
			salesman_name1: null,
			salesman_code2: '',
			salesman_name2: null,
			optometrist_code: '',
			optometrist_name: null,
			member_code: null,
			member: {
				member_code: null,
				member_name: null,
				member_group: null,
				phone: null,
			},
			doc_type: 'OR1',
			transaction_items: new List(),
			transaction_payments: new List(),
			remark: '',
			coupon_codes: new List(),
			promotion_ids_selected: new List(),
			special_options: new Map(),
			deposit_amount: 0,
			retail_amount: 0,
			total_discount: 0,
			total_quantity: 0,
			net_amount: 0,
			remaining_amount: 0,
			change_amount: 0,
			payment_amount: 0,
			salesman_exceptions: [],
			cart_exceptions: [],
			payment_exceptions: [],
			q_transaction_number: null,
			return_transaction_number: null,
			hold_transaction_number: null,
			is_service_memo: false,
			is_shop_order: true,
			is_approval_needed: false,
			is_approval_verified: false,
			approval_user_code: null,
			allowed_return_payment_methods: null,
		};
		let shopOrder = new Map(this.defaultShopOrder);
		if (process.env.DEFAULT_SALESMAN_CODE1) {
			shopOrder = shopOrder.set('salesman_code1', process.env.DEFAULT_SALESMAN_CODE1);
		}
		if (process.env.DEFAULT_SALESMAN_NAME1) {
			shopOrder = shopOrder.set('salesman_name1', process.env.DEFAULT_SALESMAN_NAME1);
		}
		this.defaultState = {
			shopOrder,
			next: null,
			showModalPromotions: null,
			showModalCartException: false,
			showModalApproval: false,
			showModalSpecialOptions: false,
			approvalUserId: null,
		};
		this.state = {
			...this.defaultState,
		};
		this.progressBar = [
			'salesman',
			'cart',
		];
		this.isLoading = this.isLoading.bind(this);
		this.isDisabled = this.isDisabled.bind(this);
		this.setIsVerified = this.setIsVerified.bind(this);
		this.getShopOrder = this.getShopOrder.bind(this);
		this.getPage = this.getPage.bind(this);
		this.getShopOrderApiData = this.getShopOrderApiData.bind(this);
		this.saveShopOrder = this.saveShopOrder.bind(this);
		this.resetShopOrder = this.resetShopOrder.bind(this);
		this.holdShopOrder = this.holdShopOrder.bind(this);
		this.confirmShopOrder = _.throttle(this.confirmShopOrder.bind(this), 5000);
		this.viewOrder = this.viewOrder.bind(this);
		this.onStepClickHandler = this.onStepClickHandler.bind(this);
		this.onSelectSalesmanHandler = this.onSelectSalesmanHandler.bind(this);
		this.onSelectMemberHandler = this.onSelectMemberHandler.bind(this);
		this.onChangeRemarkHandler = this.onChangeRemarkHandler.bind(this);
		this.onSelectLotNoHandler = this.onSelectLotNoHandler.bind(this);
		this.onAddTransactionItemHandler = this.onAddTransactionItemHandler.bind(this);
		this.onChangeTransactionItemHandler = this.onChangeTransactionItemHandler.bind(this);
		this.onRemoveTransactionItemHandler = this.onRemoveTransactionItemHandler.bind(this);
		this.onAddCouponCodeHandler = this.onAddCouponCodeHandler.bind(this);
		this.onChangeCouponCodeHandler = this.onChangeCouponCodeHandler.bind(this);
		this.onRemoveCouponCodeHandler = this.onRemoveCouponCodeHandler.bind(this);
		this.onToggleModalPromotionsHandler = this.onToggleModalPromotionsHandler.bind(this);
		this.onToggleModalCartExceptionHandler = this.onToggleModalCartExceptionHandler.bind(this);
		this.onToggleModalApprovalHandler = this.onToggleModalApprovalHandler.bind(this);
		this.onRequestApprovalHandler = this.onRequestApprovalHandler.bind(this);
		this.onToggleModalSpecialOptionsHandler = this.onToggleModalSpecialOptionsHandler.bind(this);
		this.onChangeSpecialOptionsHandler = this.onChangeSpecialOptionsHandler.bind(this);
	}

	componentDidMount() {
		const param = queryString.parse(this.props.location.search);
		const defaultShopOrderData = this.props.defaultShopOrderData;
		const retrieveHoldShopOrderInfo = this.props.retrieveHoldShopOrderInfo;

		if (Object.keys(defaultShopOrderData).length > 0) {
			const { shopOrder } = this.state;
			this.setState({
				shopOrder: shopOrder
					.set(
						'doc_type',
						'OR1'
					)
					.set(
						'return_transaction_number',
						defaultShopOrderData.return_transaction_number
					)
					.set(
						'remark',
						defaultShopOrderData.return_transaction_number
					)
					.set(
						'transaction_items',
						new List(
							defaultShopOrderData.return_items && defaultShopOrderData.return_items.map((transactionItem) => new Map(transactionItem))
						)
					)
				,
			});

			this.props.setDefaultShopOrderData({});
		}

		if (param) {
			switch (param.action) {
			case 'hold':
				if (retrieveHoldShopOrderInfo.type === cons.RETRIEVE_HOLD_SHOPORDER.SUCCESS) {
					let newState = {
						shopOrder: this.getShopOrder(retrieveHoldShopOrderInfo.data),
						salesmanDisabled: false,
					};
					if (newState.shopOrder.get('q_transaction_number')) {
						newState.salesmanDisabled = true;
						newState.shopOrder = newState.shopOrder.set('doc_type', 'OR1');
					}
					this.setState(newState);
				}
				break;
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { next } = this.state;
		let newState = {};
		const shopOrderInfo = this.props.shopOrderInfo;
		const prevShopOrderInfo = prevProps.shopOrderInfo;
		const approvalInfo = this.props.approvalInfo;
		const prevApprovalInfo = prevProps.approvalInfo;
		const shopOrderConfirmInfo = this.props.shopOrderConfirmInfo;
		const prevShopOrderConfirmInfo = prevProps.shopOrderConfirmInfo;
		const shopOrderHoldInfo = this.props.shopOrderHoldInfo;
		const prevShopOrderHoldInfo = prevProps.shopOrderHoldInfo;
		if (receiveApi(shopOrderInfo, prevShopOrderInfo, cons.UPDATE_SHOPORDER)) {
			newState.shopOrder = this.getShopOrder(shopOrderInfo.data.apiData);
			newState.salesmanDisabled = false;
			if (newState.shopOrder.get('q_transaction_number') || newState.shopOrder.get('return_transaction_number')) {
				newState.salesmanDisabled = true;
				newState.shopOrder = newState.shopOrder.set('doc_type', 'OR1');
			}
		}

		if (shopOrderInfo && shopOrderInfo !== prevShopOrderInfo) {
			if (shopOrderInfo.type === cons.UPDATE_SHOPORDER.SUCCESS) {
				if (
					shopOrderInfo.data &&
					shopOrderInfo.data.apiData &&
					shopOrderInfo.data.apiData.cart_exceptions &&
					shopOrderInfo.data.apiData.cart_exceptions.length > 0
				) {
					if (next === 'confirm') {
						if (
							shopOrderInfo.data.apiData.is_approval_needed &&
							!shopOrderInfo.data.apiData.is_approval_verified
						) {
							newState.showModalApproval = true;
						} else {
							newState.next = null;
							newState.showModalCartException = true;
						}
					} else if (next) {
						newState.next = null;
						this.props.history.push(next);
					}
				} else {
					if (next === 'confirm') {
						this.confirmShopOrder();
					} else if (next) {
						this.props.history.push(next);
						newState.next = null;
					}
				}
			}
		}

		if (approvalInfo && approvalInfo !== prevApprovalInfo) {
			switch (approvalInfo.type) {
			case cons.POST_APPROVAL.SUCCESS:
				this.setIsVerified(approvalInfo.data.is_verfied);
				break;
			case cons.POST_APPROVAL.FAILURE:
				this.setIsVerified(false);
				break;
			}
		}

		if (shopOrderConfirmInfo && shopOrderConfirmInfo !== prevShopOrderConfirmInfo) {
			if (shopOrderConfirmInfo.type === cons.CONFIRM_SHOPORDER.SUCCESS) {
				if (next) {
					newState.next = null;
					alert(`${shopOrderConfirmInfo.data.apiData.transaction_number} is saved`);
					this.resetShopOrder();
				}
			} else if (shopOrderConfirmInfo.type === cons.CONFIRM_SHOPORDER.FAILURE) {
				newState.next = null;
			}
		}

		if (shopOrderHoldInfo && shopOrderHoldInfo !== prevShopOrderHoldInfo) {
			switch (shopOrderHoldInfo.type) {
			case cons.HOLD_SHOPORDER.SUCCESS:
				alert(`${shopOrderHoldInfo.data.transaction_number} is saved`);
				break;
			case cons.HOLD_SHOPORDER.FAILURE:
				if (shopOrderHoldInfo.error.code) {
					alert(loc[shopOrderHoldInfo.error.code]);
				}
				break;
			}
		}

		this.setState(newState);
	}

	isLoading() {
		const { shopOrderInfo, shopOrderConfirmInfo } = this.props;
		return shopOrderInfo.isFetching || shopOrderConfirmInfo.isFetching;
	}

	isDisabled() {
		let ret = this.isLoading();
		if (ret) {
			return ret;
		}
		const { shopOrder } = this.state;
		return !shopOrder.get('salesman_code1');
	}

	setIsVerified(isVerified) {
		const { approvalUserId, shopOrder } = this.state;
		this.setState({
			shopOrder: shopOrder
				.set('is_approval_verified', isVerified)
				.set('approval_user_code', approvalUserId)
			,
			showModalApproval: !isVerified,
		}, isVerified ? this.saveShopOrder : undefined);
	}

	getShopOrder(shopOrderApiData) {
		let { shopOrder } = this.state;
		shopOrder = shopOrder.merge(shopOrderApiData);
		shopOrder = shopOrder
			.set(
				'transaction_items',
				new List(
					shopOrder.get('transaction_items').map((transactionItem) => new Map(transactionItem))
				)
			)
			.set(
				'transaction_payments',
				new List(
					shopOrder.get('transaction_payments').map((transactionPayment) => new Map(transactionPayment))
				)
			)
			.set(
				'coupon_codes',
				new List(
					shopOrder.get('coupon_codes')
				)
			)
			.set(
				'promotion_ids_selected',
				new List(
					shopOrder.get('promotion_ids_selected')
				)
			)
		;
		if (shopOrder.get('special_options')) {
			let specialOptions = {};
			shopOrder.get('special_options').forEach((specialOption) => {
				specialOptions[specialOption.name] = specialOption.value;
			});
			shopOrder = shopOrder.set('special_options', new Map(specialOptions));
		}
		return shopOrder;
	}

	getPage(props) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.page;
	}

	getShopOrderApiData() {
		const { shopOrder } = this.state;
		let ret = shopOrder.toJS();

		// Handle payment
		ret.transaction_payments = ret.transaction_payments.map((transactionPayment) => ({
			...transactionPayment,
			amount: parseFloat(transactionPayment.amount) || 0,
		}));

		// Handle special options
		let special_options = [];
		for (let i in ret.special_options) {
			special_options.push({
				name: i,
				value: ret.special_options[i],
			});
		}
		ret.special_options = special_options;

		ret.tracking = {
			type: 'web',
			uuid: uuidv4(),
			deviceUuid: new DeviceUUID().get(),
			userAgent: getUserAgent(),
		};

		return ret;
	}

	saveShopOrder() {
		let param = {
			payload: {
				apiData: this.getShopOrderApiData(),
			},
		};
		param.payload.apiData.step = this.getPage();
		this.props.updateShopOrder(param);
	}

	resetShopOrder() {
		this.setState({
			...this.defaultState,
		}, () => {
			let param = {
				payload: {
					apiData: this.getShopOrderApiData(),
				},
				reset: true,
			};
			param.payload.apiData.step = 'salesman';
			this.props.updateShopOrder(param);
			this.props.history.push('salesman');
		});
	}

	holdShopOrder() {
		const param = {
			payload: {
				apiData: this.getShopOrderApiData(),
			},
		};
		this.props.holdShopOrder(param);
	}

	confirmShopOrder() {
		const param = {
			payload: {
				apiData: this.getShopOrderApiData(),
			},
		};
		this.props.confirmShopOrder(param);
	}

	viewOrder() {
		const { shopOrderConfirmInfo } = this.props;
		const { signature } = this.state;
		if (
			shopOrderConfirmInfo &&
			shopOrderConfirmInfo.data &&
			shopOrderConfirmInfo.data.apiData &&
			shopOrderConfirmInfo.data.apiData.transaction_number
		) {
			const param = {
				payload: {
					trx_no: shopOrderConfirmInfo.data.apiData.transaction_number,
					signature,
				},
			};
			this.props.viewOrder(param);
		}
	}

	onStepClickHandler(step) {
		let handler;
		switch (step) {
		case 'salesman':
		case 'cart':
			handler = this.saveShopOrder;
			break;
		case 'confirm':
			handler = this.confirmShopOrder;
			break;
		}
		this.setState({
			next: step,
		}, handler);
	}

	onSelectSalesmanHandler(salesmanType, salesman) {
		const { shopOrder } = this.state;
		switch (salesmanType) {
		case 'salesman1':
			this.setState({
				shopOrder: shopOrder
					.set('salesman_code1', salesman ? salesman.user_id : null)
					.set('salesman_name1', salesman ? salesman.user_name : null)
				,
			});
			break;
		}
	}

	onSelectMemberHandler(member) {
		const { shopOrder } = this.state;
		this.setState({
			shopOrder: shopOrder
				.set('member_code', member.member_code)
				.set('member', member)
			,
		});
	}

	onChangeRemarkHandler(remark) {
		const { shopOrder } = this.state;
		this.setState({
			shopOrder: shopOrder.set('remark', remark),
		});
	}

	onSelectLotNoHandler(index, lotNo) {
		const { shopOrder } = this.state;
		const transactionItem = shopOrder.get('transaction_items').get(index);
		this.setState({
			shopOrder: shopOrder
				.set(
					'transaction_items',
					shopOrder.get('transaction_items').set(
						index,
						transactionItem.set('lot_no', lotNo.lot_no)
					)
				)
			,
		}, this.saveShopOrder);
	}

	onAddTransactionItemHandler(transactionItem) {
		const { shopOrder } = this.state;
		this.setState({
			shopOrder: shopOrder
				.set(
					'transaction_items',
					shopOrder.get('transaction_items').push(transactionItem)
				)
			,
		}, this.saveShopOrder);
	}

	onChangeTransactionItemHandler(index, data) {
		const { shopOrder } = this.state;
		const transactionItem = shopOrder.get('transaction_items').get(index);
		this.setState({
			shopOrder: shopOrder
				.set(
					'transaction_items',
					shopOrder.get('transaction_items').set(
						index,
						transactionItem
							.set('item_quantity', data.item_quantity)
							.set('manual_price_amount', data.manual_price_amount)
							.set('discount_percentage', data.discount_percentage)
							.set('discount_reduction', data.discount_reduction)
							.set('discount_amount', data.discount_amount)
					)
				)
			,
		}, this.saveShopOrder);
	}

	onRemoveTransactionItemHandler(index) {
		const { shopOrder } = this.state;
		this.setState({
			shopOrder: shopOrder
				.set(
					'transaction_items',
					shopOrder.get('transaction_items').delete(index)
				)
			,
		}, this.saveShopOrder);
	}

	onAddCouponCodeHandler(couponCode) {
		const { shopOrder } = this.state;
		this.setState({
			shopOrder: shopOrder
				.set(
					'coupon_codes',
					shopOrder.get('coupon_codes').push(couponCode),
				)
			,
		}, this.saveShopOrder);
	}

	onChangeCouponCodeHandler(couponCode, data) {
		if (data.quantity <= 0) {
			return this.onRemoveCouponCodeHandler(couponCode);
		}

		const { shopOrder } = this.state;
		let couponCodeMap = {};
		shopOrder.get('coupon_codes').forEach((couponCode) => {
			if (!couponCodeMap[couponCode]) {
				couponCodeMap[couponCode] = {
					couponCode,
					quantity: 0,
				};
			}
			couponCodeMap[couponCode].quantity++;
		});
		couponCodeMap[couponCode].quantity = data.quantity;

		let tmp = Object.values(couponCodeMap).sort((a, b) => a.couponCode.localeCompare(b.couponCode));
		let couponCodes = [];
		for (let i = 0; i < tmp.length; i++) {
			for (let j = 0; j < tmp[i].quantity; j++) {
				couponCodes.push(tmp[i].couponCode);
			}
		}

		this.setState({
			shopOrder: shopOrder
				.set(
					'coupon_codes',
					new List(couponCodes)
				)
			,
		}, this.saveShopOrder);
	}

	onRemoveCouponCodeHandler(couponCode) {
		const { shopOrder } = this.state;
		this.setState({
			shopOrder: shopOrder
				.set(
					'coupon_codes',
					shopOrder.get('coupon_codes').filterNot((c) => c === couponCode)
				)
			,
		}, this.saveShopOrder);
	}

	onToggleModalPromotionsHandler(tab) {
		const { showModalPromotions } = this.state;
		this.setState({
			showModalPromotions: showModalPromotions ? null : tab,
		});
	}

	onToggleModalCartExceptionHandler() {
		const { showModalCartException } = this.state;
		this.setState({
			showModalCartException: !showModalCartException,
		});
	}

	onToggleModalApprovalHandler() {
		this.setState({
			showModalApproval: false,
		});
	}

	onRequestApprovalHandler({ user_id, code }) {
		const param = {
			payload: {
				user_id,
				code,
			},
		};
		this.setState({
			approvalUserId: user_id,
		});
		this.props.postApproval(param);
	}

	onToggleModalSpecialOptionsHandler() {
		const { showModalSpecialOptions } = this.state;
		this.setState({
			showModalSpecialOptions: !showModalSpecialOptions,
		});
	}

	onChangeSpecialOptionsHandler(name, value) {
		const { shopOrder } = this.state;
		const specialOptions = shopOrder.get('special_options');
		this.setState({
			shopOrder: shopOrder.set('special_options', specialOptions.set(name, value)),
		});
	}

	render() {
		const { shopOrder, showModalPromotions, showModalCartException, showModalApproval, showModalSpecialOptions } = this.state;
		const { shopOrderInfo, history } = this.props;
		const isDisabled = this.isDisabled();
		const isLoading = this.isLoading();
		const commonProps = {
			salesOrder: shopOrder,
			type: 'shopOrder',
			isDisabled,
			isLoading,
			onStepClick: this.onStepClickHandler,
			onToggleModalSpecialOptions: this.onToggleModalSpecialOptionsHandler,
		};
		const page = this.getPage();
		let body = null;
		switch (page) {
		case 'salesman':
			body = (
				<PageShopOrderSalesman
					next={ this.progressBar[1] }
					onSelectSalesman={ this.onSelectSalesmanHandler }
					onSelectMember={ this.onSelectMemberHandler }
					{ ...commonProps }
				/>
			);
			break;
		case 'cart':
			body = (
				<PageShopOrderCart
					next="confirm"
					onChangeRemark={ this.onChangeRemarkHandler }
					onSelectLotNo={ this.onSelectLotNoHandler }
					onToggleModalPromotions={ this.onToggleModalPromotionsHandler }
					onAddTransactionItem={ this.onAddTransactionItemHandler }
					onChangeTransactionItem={ this.onChangeTransactionItemHandler }
					onRemoveTransactionItem={ this.onRemoveTransactionItemHandler }
					onAddCouponCode={ this.onAddCouponCodeHandler }
					onChangeCouponCode={ this.onChangeCouponCodeHandler }
					onRemoveCouponCode={ this.onRemoveCouponCodeHandler }
					{ ...commonProps }
				/>
			);
			break;
		}

		return (
			<Fragment>
				<div className="uk-flex uk-flex-column">
					<ProgressBar
						name="shopOrder"
						page={ page }
						items={ this.progressBar }
						salesOrder={ shopOrder }
						history={ history }
						onStepClick={ this.onStepClickHandler }
						onReset={ this.resetShopOrder }
						onToggleModalPromotions={ this.onToggleModalPromotionsHandler }
					/>
					<div>
						{ body }
					</div>
				</div>

				<ModalPromotions
					isOpen={ showModalPromotions }
					isLoading={ isLoading }
					type="shopOrder"
					current={ page }
					onToggle={ this.onToggleModalPromotionsHandler }
					onApply={ this.onApplyPromotionsHandler }
					salesOrder={ shopOrder }
				/>

				<ModalCartException
					isOpen={ showModalCartException }
					onToggle={ this.onToggleModalCartExceptionHandler }
					data={ shopOrderInfo }
				/>

				<ModalApproval
					isOpen={ showModalApproval }
					onToggle={ this.onToggleModalApprovalHandler }
					onSubmit={ this.onRequestApprovalHandler }
				/>

				<ModalSpecialOptions
					isOpen={ showModalSpecialOptions }
					onToggle={ this.onToggleModalSpecialOptionsHandler }
					onChange={ this.onChangeSpecialOptionsHandler }
					salesOrder={ shopOrder }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		defaultShopOrderData: state.defaultShopOrderData,
		shopOrderInfo: state.shopOrderInfo,
		shopOrderConfirmInfo: state.shopOrderConfirmInfo,
		shopOrderHoldInfo: state.shopOrderHoldInfo,
		retrieveHoldShopOrderInfo: state.retrieveHoldShopOrderInfo,
		viewOrderInfo: state.viewOrderInfo,
		approvalInfo: state.approvalInfo,
	}),
	(dispatch) => ({
		setDefaultShopOrderData: para => dispatch(setDefaultShopOrderData(para)),
		updateShopOrder: para => dispatch(updateShopOrder(para)),
		postApproval: (para) => dispatch(postApproval(para)),
		confirmShopOrder: para => dispatch(confirmShopOrder(para)),
		holdShopOrder: para => dispatch(holdShopOrder(para)),
		viewOrder: para => dispatch(viewOrder(para)),
		printOrder: para => dispatch(printOrder(para)),
	})
)(PageShopOrder);